import React, { Component } from 'react';
import { connect } from 'react-redux';
import CouvertureView from './views/CouvertureView';
import { GET_CURRENT_NUMERO } from "../../../../Queries";
import { Query } from "react-apollo";
import PropTypes from "prop-types";
import animations from "./animations.js";
import { withRouter } from "react-router-dom";

let supportsTouch, clientWidth, clientHeight;
let BodyScrollModule = null;

if(typeof window !== "undefined"){
    supportsTouch = 'ontouchstart' in window || navigator.msMaxTouchPoints;
    const body = document.body;

    clientWidth = window.innerWidth
        || document.documentElement.clientWidth
        || document.body.clientWidth;

    clientHeight = window.innerHeight
        || document.documentElement.clientHeight
        || document.body.clientHeight;
}

class Couverture extends Component {

    constructor(props) {
        super(props);

        this.state = {
            warnings : [
               "Veuillez ajouter un article à la première catégorie",
               "Veuillez ajouter des catégories à la publication"
            ],
            heightCouverture: "100vh",
            bodyScrollModuleLoaded: false,
            heightLateral: "0px",
            ref: null
        };

    }

    setReference = (ref) => {
        this.ref = ref;
    };

    getFirstArticleToRead(data) {

        const categoriesArr = data.currentNumero.categories.slice(0);

        if(categoriesArr.length === 0) {
            return {
                error: true,
                warning: this.state.warnings[1]
            }
        }

        const category = categoriesArr.sort((a, b) => {
            return (a.order - b.order)
        })[0];

        let article = data.currentNumero.articles.find((article) => article.category._id === category._id);

        if(category.timeForRead && "0" !== category.timeForRead){
            article = category;
        }

        if(!article) {
            return {
                error: true,
                warning: this.state.warnings[0]
            }
        } else {
            return {
                error: false,
                article,
                type: (category.timeForRead) ? 'chapitre' : 'article'
            }
        }

    }

    async setHeightCouverture() {
        if(typeof window !== "undefined") {
            let iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);

            let isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

            if (isMobile) {
                let heightLateral =  this.getLateralHeight();
                if(clientWidth > 425) {
                    heightLateral = 0;
                }

                this.setState({
                    heightCouverture: (window.innerHeight) ? window.innerHeight - heightLateral + "px" : "100vh",
                    heightLateral,
                });

                return true;
            }
        }
    }

    getLateralHeight() {
        if(typeof window === "undefined") return "0px";
        if(!document.querySelector(".lateralBarView")) return 0;
        return document.querySelector(".lateralBarView").getBoundingClientRect().height;
    }

    handleScroll() {
        this.setHeightCouverture().then(() => {});
    }

    componentWillUnmount() { let self = this;

        if(self.state.bodyScrollModuleLoaded) {
            BodyScrollModule.clearAllBodyScrollLocks();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevState.bodyScrollModuleLoaded !== this.state.bodyScrollModuleLoaded){
            window.scrollTo(0,0);
            BodyScrollModule.disableBodyScroll(document.querySelector(".icon2"));
        }
        if(this.props.match.path !== prevProps.match.path) {
            if(typeof window.scrollTo !== "undefined"){
                window.scrollTo(0,0);
                BodyScrollModule.disableBodyScroll(document.querySelector(".icon2"));
            }
        }
    }

    componentDidMount() {
        this.props.changeKindView("couverture");
        this.setHeightCouverture().then(() => {});

        import('body-scroll-lock').then((module) => {
            BodyScrollModule = module;
            this.setState({
                bodyScrollModuleLoaded: true
            });
        });
    }

    getArticleCouv(data) {

        const articles = data.currentNumero.articles;
        const couverture = data.currentNumero.couverture;
        let firstArticle = {};
        let others = [];

        couverture.forEach((articleId, index) => {
            if(index === 0){
                firstArticle = articles.find(( article) => article._id === articleId);
            }
            else others.push(articles.find(( article) => article._id === articleId));
        });

        return {
            firstArticle,
            others
        }

    }

    openLink = (link, e, action) => {

        e.preventDefault();

        if(action === "COMMENCER_LECTURE"){
            this.props.startRead();
            this.props.history.push(link);
            this.props.startRead();
        } else if (action === "REPRENDRE_LECTURE"){
            this.props.startRead();
            this.props.history.push(link);
            this.props.restartRead();
        } else if (action === "OUVRIR_SOMMAIRE"){
           this.props.toggleMenu();
        }
    };

    render() { const { magazine } = this.props; let that = this;
        return (
            <Query query={ GET_CURRENT_NUMERO }>
                {({loading, error, data, client}) => {

                    if(!data.currentNumero) {
                        that.forceUpdate();
                    }

                    if (loading) return "Loading GET_CURRENT_NUMERO couverture...";
                    if (error) return `Error! ${error.message}`;

                    let articles = data.currentNumero.articles;

                    if(!articles) return <div className='warning'> { this.state.warnings[0] } </div>;
                    let firstRead = this.getFirstArticleToRead(data);

                    if(!firstRead.error)
                        return (
                            <div ref={ this.setReference }>
                                <CouvertureView
                                    magazine={ this.context.magazine }
                                    numero={ data.currentNumero }
                                    match={ this.props.match }
                                    heightLateral={ this.state.heightLateral }
                                    couverture={ this.getArticleCouv(data) }
                                    user={ this.props.user }
                                    firstRead={ firstRead.article }
                                    openLink={ this.openLink }
                                    heightCouverture={ this.state.heightCouverture }
                                    articles={ articles }
                                    captureEntreeRead={ this.props.captureEntreeRead }
                                />
                            </div>
                        );
                    else
                        return <div className='warning'> { firstRead.warning } </div>
                }}
            </Query>
        )
    }
}

const mapStateToProps = (state, props) => {
    return {
        magazine: state.magazine,
        user: state.user
    };
};

const mapDispatchToProps = (dispatch) => {
    return({
        startRead: () => {
            dispatch({
                type: 'COMMENCER_LECTURE'
            });
        },
        restartRead: () => {
            dispatch({
                type: 'REPRENDRE_LECTURE'
            });
        },
        captureCouvRead: () => {
            dispatch({
                type: 'CLICK_LECTURE_UNE'
            });
        },
        captureEntreeRead: (number) => {

            dispatch({
                type: 'CLICK_ENTREE_COUV',
                number
            });
        },
        changeCurrentArticle: () => {
            dispatch({
                type: 'CHANGE_CURRENT_ARTICLE',
                currentArticle: null,
                view: "couverture"
            });
        },
        changeKindView: (kind) => {
            dispatch({
                type: 'CHANGE_VIEW',
                kind,
            });
        },
    })
};

Couverture.contextTypes = {
    magazine: PropTypes.object
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Couverture));
