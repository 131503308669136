import { Component } from "react";

import "./BebasNeue.scss";

export default class BebasNeue extends Component {

    render() {
        return null;
    }

}
