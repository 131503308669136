import React from "react";

import { Link } from "react-router-dom";
import classNames from "classnames";
import { SettingsContext } from "../../../../Magazine/MagazineSettings";

import "../style/couverture.scss";
import { ImageComponent } from "../../Article/components/Header";

import img2020 from "../assets/2020.svg";

function truncate(input, length) {
  if (input.length > length) return input.substring(0, length) + "...";
  else return input;
}

const ProgressiveContainer = ({ src, loading }) => {
  const classes = classNames({
    ready: loading,
    "inner-head": true,
  });

  return (
    <div className={classes}>
      <div className="fx-img" />
      {src && <img src={src} alt="an image" />}
    </div>
  );
};

const TitleCouverture = ({ numero, fonts }) => {
  let title = "";
  let subtitle = null;

  if (numero.custom.couverture) {
    if (numero.custom.couverture.titre_personnalise) {
      title = numero.custom.couverture.titre_personnalise;
    } else {
      title = numero.title;
    }

    if (numero.custom.couverture.sous_titre_personnalise) {
      subtitle = numero.custom.couverture.sous_titre_personnalise;
    }
  } else {
    title = numero.title;
  }

  let returnArr = [];

  returnArr.push(
    <h1
      style={fonts.family1}
      data-text="true"
      className="title"
      key={numero._id + "_h1"}
      dangerouslySetInnerHTML={{ __html: title }}
    />
  );

  if (subtitle) {
    returnArr.push(
      <span
        style={fonts.family1}
        data-text="true"
        className="subtitle"
        key={numero._id + "_span"}
        dangerouslySetInnerHTML={{ __html: subtitle }}
      />
    );
  }

  return returnArr;
};

const CouvertureView = ({
  user,
  magazine,
  numero,
  match,
  couverture,
  firstRead,
  heightCouverture,
  captureEntreeRead,
  articles,
  openLink,
  heightLateral,
}) => {
  let lastArticleRead = () => {
    let lasts = user.lastArticlesRead.filter((obj) => {
      return numero._id === obj.numero._id;
    });
    if (lasts) {
      return lasts.sort((a, b) => {
        return b.date - a.date;
      })[0];
    }
    return false;
  };

  return (
    <SettingsContext.Consumer>
      {({ fonts, logo }) => {
        let url = null;

        let backgroundImage = numero.wallpaper.url;

        if (numero.custom.couverture) {
          backgroundImage = numero.custom.couverture.image;
        }

        let lastArticleReadObj = lastArticleRead();

        if (lastArticleReadObj) {
          if (lastArticleReadObj.category) {
            url = `/${lastArticleReadObj.numero.slug}/${lastArticleReadObj.category.slug}/${lastArticleReadObj.slug}`;
          } else {
            url = `/${lastArticleReadObj.numero.slug}/${lastArticleReadObj.slug}`;
          }
        } else {
          if (firstRead.category) {
            url = `/${numero.slug}/${firstRead.category.slug}/${firstRead.slug}`;
          } else {
            url = `/${numero.slug}/${firstRead.slug}`;
          }
        }

        let image = {
          url: backgroundImage,
          width: null,
          height: null,
        };

        return (
          <div className="couverture" style={{ height: heightCouverture }}>
            <div
              className="couvertureInner"
              style={{ paddingBottom: heightLateral + "px" }}
            >
              <div className="couvertureImage">
                <ImageComponent image={image} />
              </div>
              <div className="couvertureInfos">
                <span className="title-couverture">
                  { numero._id === '6729' &&
                    <i className="ico-ph-2019" />
                  }
                  { numero._id !== '6729' &&
                    <img className="year" src={img2020} alt="" />
                  }
                  <i className="ico-ph-le-rapport-annuel-dactivites" />
                </span>

                <a href="https://www.parishabitat.fr/"><img src="/medias/uploads/2020/07/logo.svg" alt="" /></a>

                {lastArticleReadObj && (
                  <Link
                    to={url}
                    onClick={(e) => openLink(url, e, "REPRENDRE_LECTURE")}
                    className="start-btn"
                    style={fonts.family1}
                  >
                    <div>
                      <span className="inner-start">Reprendre la lecture</span>
                    </div>
                  </Link>
                )}

                {!lastArticleReadObj && (
                  <Link
                    to={url}
                    onClick={(e) => openLink(url, e, "OUVRIR_SOMMAIRE")}
                    className="start-btn"
                    style={fonts.family1}
                  >
                    <div>
                      <span className="inner-start">Commencer la lecture</span>
                    </div>
                  </Link>
                )}
              </div>
            </div>
          </div>
        );
      }}
    </SettingsContext.Consumer>
  );
};

export default CouvertureView;

const getCouverture = (categories, numero) => {
  const firstArticle = numero.articles.filter((article) => {
    return article.category._id === categories[0]._id;
  })[0];

  if (firstArticle) return firstArticle;
};

const ChapitreView = ({
  user,
  categories,
  numero,
  articles,
  fonts,
  captureEntreeRead,
  allArticles,
}) => {
  let chapitre = (article) => {
    return categories.find((category) => category._id === article.category._id);
  };

  let getArticlesByCategory = (articles, category) => {
    return articles.filter((article) => article.category._id === category._id);
  };

  let calculCircle = (articles, user) => {
    let self = this;

    let min = 250; // no read
    let max = 119; // all read

    if (articles) {
      let nbrArticles = articles.length;

      let nbrReaded = articles.reduce((acc, val) => {
        return isRead(user, val) === false ? acc : acc + 1;
      }, 0);

      let diff = nbrReaded / nbrArticles;
      let differentiel = min - max;

      return min - diff * differentiel;
    }

    return min;
  };

  let isRead = (user, article) => {
    if (user.articles) {
      return user.articles.some((articleObj) => {
        return (
          articleObj._id.split("_")[0] === article._id.split("_")[0] &&
          articleObj.readed
        );
        //return (articleObj._id === article._id && articleObj.readed);
      });
    }
    return false;
  };

  let isLastRead = (user, articles) => {
    if (user.lastArticlesRead) {
      return articles.some((articleObj) => {
        return user.lastArticlesRead.find((obj) => {
          return articleObj._id.split("_")[0] === obj._id.split("_")[0];
        });
      });
    }
    return false;
  };

  let articlesAlreadyReadInCategory = (user, categoryArticles) => {
    if (user.articles) {
      return categoryArticles.some((article) => {
        return user.articles.some((articleObj) => {
          return (
            articleObj._id.split("_")[0] === article._id.split("_")[0] &&
            articleObj.readed
          );
        });
      });
    }
    return false;
  };

  let getColorCategory = (article) => {
    let chapitreObj = chapitre(article);
    let chapitreArticles = getArticlesByCategory(allArticles, chapitreObj);

    let percentageRead = calculCircle(chapitreArticles, user);
    let couleurRead = "#c3c3c3";

    if (percentageRead === 119) {
      couleurRead = "#4EC575";
    }

    if (
      isLastRead(user, chapitreArticles) ||
      (articlesAlreadyReadInCategory(user, chapitreArticles) &&
        percentageRead !== 119)
    ) {
      couleurRead = "#257FBC";
    }

    return couleurRead;
  };

  return (
    <div>
      {articles.map((article, index) => {
        let chap = chapitre(article);

        if (article) {
          if (!chap) return null;

          let readed = isRead(user, article);

          let classes = classNames({
            chapitre: true,
            readed: isRead(user, article),
          });

          return (
            <div className={classes} key={index} data-text="true">
              <Link
                to={`/${numero.slug}/${chapitre(article).slug}/${article.slug}`}
                onClick={() => captureEntreeRead(index + 1)}
              >
                <span
                  className="chapitre-titre"
                  style={fonts.family2}
                  data-text="true"
                >
                  <span
                    className="number"
                    style={{ backgroundColor: chapitre(article).couleur }}
                  >
                    {chapitre(article).number}
                  </span>
                  {chapitre(article).title}
                </span>
                <span className="title" data-text="true" style={fonts.family3}>
                  {truncate(article.title, 58)}
                </span>
                <div className="outer-i">
                  <div className="time" data-text="true" style={fonts.family1}>
                    <i className="icon-time" />
                    <span className="inner">{article.timeForRead}mn</span>
                  </div>
                  {readed && (
                    <div
                      className="status"
                      data-text="true"
                      style={fonts.family1}
                    >
                      <i className="ico-ph-lu" />
                      <span className="inner">Lu</span>
                    </div>
                  )}
                  {!readed && (
                    <div
                      className="status"
                      data-text="true"
                      style={fonts.family1}
                    >
                      <i className="ico-ph-non_lu" />
                      <span className="inner">Non lu</span>
                    </div>
                  )}
                </div>
              </Link>
            </div>
          );
        }
        return null;
      })}
    </div>
  );
};
