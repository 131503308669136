import React, { PureComponent } from "react";
import classNames from "classnames";
import { GET_MENU_NUMEROS } from "../../../../../Queries";
import { SettingsContext } from "../../../../Magazine/MagazineSettings";
import { Query } from "react-apollo";

import "../style/menu-numero.scss";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Loadable from "react-loadable";
import { FooterMenu } from "../../Menu/views/MenuView";

const Loading = () => null;

const SvgCircleOuter = Loadable({
  loader: () => import("./SvgCircleOuter"),
  loading: Loading,
});

class NumeroInList extends PureComponent {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.myRef2 = React.createRef();
  }

  updateDimensions() {
    let that = this;
    // ratio 375 x 508
    if (that.myRef.current) {
      setTimeout(function () {
        if (that.myRef2.current) {
          let width = that.myRef2.current.offsetWidth;
          let height = that.myRef.current.offsetHeight;

          that.props.changeImageRatio({
            imageRatio: {
              width: width,
              height: (508 * width) / 375,
            },
          });
        }
      }, 100);
    }
  }

  isLastNumeroArticleReaded = () => {
    if (!this.props.user.lastArticleReadAll) return false;
    return (
      this.props.user.lastArticleReadAll.numero._id === this.props.numero._id
    );
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.menu !== this.props.menu) {
      this.updateDimensions();

      if (typeof window.scrollTo !== "undefined") {
        window.scrollTo(0, 0);
      }
    }
  }

  componentDidMount() {
    let that = this;
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  render() {
    let {
      numero,
      numeroObj,
      user,
      handleChange,
      imageRatio,
      getUrlNumeroItem,
      calculCircle,
    } = this.props;

    let style = {};

    if (numero.couverture_kiosque) {
      style.backgroundImage = "url(" + numero.couverture_kiosque + ")";
      style.width = imageRatio.width + "px";
      style.height = imageRatio.height + "px";
    }

    let isNumeroRead = this.props.isNumeroRead(numero);
    let url = getUrlNumeroItem(numero);

    const classes = classNames({
      numeroInList: true,
      lu: isNumeroRead.status === "lu",
      non_lu: isNumeroRead.status === "non_lu",
      en_cours: isNumeroRead.status === "en_cours",
    });

    let circleValue = calculCircle(numero, numero.nbrArticles, user);

    return (
      <div className={classes}>
        <div className="numeroInListOuter">
          <div className="numeroInListInner">
            <Link
              to={url}
              onClick={(e) => this.props.changeNumeroAndGoLink(url, numero, e)}
            >
              <div className="numeroInListInnerLink" ref={this.myRef2}>
                <div
                  className="couvertureKiosque"
                  ref={this.myRef}
                  style={style}
                />
                <span className="title">{numero.title}</span>
              </div>
            </Link>
          </div>
        </div>
        <div className="numeroInfo">
          <div className="status">
            {isNumeroRead.status === "lu" && (
              <div className={"inner " + isNumeroRead.status}>
                <i className="ico-ph-read-circle" />
                <span>Lu</span>
              </div>
            )}
            {isNumeroRead.status === "non_lu" && (
              <div className={"inner " + isNumeroRead.status}>
                <i className="ico-ph-read-circle" />
                <span>Non lu</span>
              </div>
            )}
            {isNumeroRead.status === "en_cours" && (
              <div className={"inner " + isNumeroRead.status}>
                <i className="ico-ph-progress" />
                <span>En cours</span>
              </div>
            )}
          </div>
          <div className="iconsStatus">
            <div className="icon">
              {isNumeroRead.status === "en_cours" && (
                <SvgCircleOuter circleValue={circleValue} />
              )}
              {isNumeroRead.status === "lu" && <i className="ico-ph-read" />}
              {isNumeroRead.status === "non_lu" && (
                <i className="ico-ph-read" />
              )}
              {isNumeroRead.status === "en_cours" && (
                <i className="ico-ph-progress-dot" />
              )}
            </div>
          </div>
          {this.isLastNumeroArticleReaded() && isNumeroRead.status !== "lu" && (
            <span className="lecture-en-cours">Lecture en cours</span>
          )}
          {numero.timeForRead && (
            <div className="timing">
              <i className="ico-ph-time" />
              <span>{numero.timeForRead}</span>
            </div>
          )}
        </div>
      </div>
    );
  }
}

class MenuNumeroFilter extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      filter: null,
    };
  }

  getTypePublications() {
    let types = [];

    if (this.props.numeros) {
      this.props.numeros.forEach((numero) => {
        if (
          numero.type && 
          types.filter((type) => type.slug === numero.type.slug)[0] ===
          undefined
        ) {
          types.push(numero.type);
        }
      });
    }

    return types;
  }

  changeTypeSelection = (typePublication) => {
    this.setState({
      filter: typePublication,
    });
  };

  resetTypeSelection = () => {
    this.setState({
      filter: null,
    });
  };

  isCurrent(typePublication = null) {
    if (this.state.filter) {
      if (!typePublication) return false;
      return typePublication.slug === this.state.filter.slug;
    } else {
      return !typePublication;
    }
  }

  render() {
    let typePublications = this.getTypePublications();

    const classes = classNames({
      typePublication: true,
      current: this.isCurrent(),
    });

    return (
      <div className="menuNumeroFilter">
        <div className="filter">
          <div className={classes} onClick={this.resetTypeSelection}>
            <span>Tout</span>
          </div>
          {typePublications.map((typePublication) => {
            const classes = classNames({
              typePublication: true,
              current: this.isCurrent(typePublication),
            });

            return (
              <div
                className={classes}
                onClick={() => this.changeTypeSelection(typePublication)}
                key={typePublication._id}
              >
                <span>{typePublication.title}</span>
              </div>
            );
          })}
        </div>
        {this.props.children(this.state.filter)}
      </div>
    );
  }
}

export class MenuNumeroView extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      imageRatio: {
        width: null,
        height: null,
      },
    };

    this.changeImageRatio = this.changeImageRatio.bind(this);
    this.isNumeroRead = this.isNumeroRead.bind(this);
    this.getUrlNumeroItem = this.getUrlNumeroItem.bind(this);
  }

  changeImageRatio(imageRatio) {
    if (this.props.menu) {
      if (this.state.imageRatio.height !== imageRatio.imageRatio.height) {
        this.setState({
          imageRatio: {
            width: imageRatio.imageRatio.width,
            height: imageRatio.imageRatio.height,
          },
        });
      }
    }
  }

  lastArticleRead = (numero) => {
    let lastArticlesRead = this.props.user.lastArticlesRead.filter((obj) => {
      return numero._id === obj.numero._id;
    });

    if (lastArticlesRead) {
      return lastArticlesRead.sort((a, b) => {
        return b.date - a.date;
      })[0];
    }
    return false;
  };

  changeNumeroAndGoLink = (link, numero, e) => {
    let that = this;
    e.preventDefault();

    this.props.toggleMenu(false, numero);
    that.props.history.push(link);
    that.props.setMenuNumero(false);
  };

  getUrlNumeroItem(numero) {
    let url = null;
    let lastArticleReadObj = this.lastArticleRead(numero);

    if (lastArticleReadObj) {
      if (lastArticleReadObj.category) {
        url = `/${lastArticleReadObj.numero.slug}/${lastArticleReadObj.category.slug}/${lastArticleReadObj.slug}`;
      } else {
        url = `/${lastArticleReadObj.numero.slug}/${lastArticleReadObj.slug}`;
      }
    } else {
      url = `/${numero.slug}`;
    }

    return url;
  }

  isNumeroRead(numero) {
    let articlesNumeroReaded = this.props.user.articles.filter((article) => {
      return (
        article.numero._id === numero._id &&
        article.readed &&
        article.type === "article"
      );
    });

    let categoriesNumeroReaded = this.props.user.articles.filter((article) => {
      return (
        article.numero._id === numero._id &&
        article.readed &&
        article.type === "chapitre"
      );
    });

    if (articlesNumeroReaded.length === 0) {
      if (categoriesNumeroReaded.length > 0) {
        return {
          status: "en_cours",
        };
      }
      return {
        status: "non_lu",
      };
    }

    let chapitresToReadLength = numero.nbrChapitresToRead;
    let allArticlesNumero = numero.nbrArticles;

    if (articlesNumeroReaded.length === allArticlesNumero) {
      if (chapitresToReadLength < 0) {
        return {
          status: "lu",
        };
      } else {
        if (categoriesNumeroReaded.length === chapitresToReadLength) {
          return {
            status: "lu",
          };
        } else {
          return {
            status: "en_cours",
          };
        }
      }
    }

    if (articlesNumeroReaded.length > 0) {
      return {
        status: "en_cours",
      };
    }
  }

  handleChange = (numero, e) => {
    e.preventDefault();
    this.props.setMenuNumero(false);
    this.props.toggleMenu(false, numero);
  };

  isRead = (user, article) => {
    if (user.articles) {
      return user.articles.some((articleObj) => {
        return (
          articleObj._id.split("_")[0] === article._id.split("_")[0] &&
          articleObj.readed
        );
        //return (articleObj._id === article._id && articleObj.readed);
      });
    }
    return false;
  };

  getArticlesReadByNumero = (user, numero) => {
    if (user.articles) {
      return user.articles.filter((articleObj) => {
        return articleObj.numero._id === numero._id && articleObj.readed;
      });
    }
    return false;
  };

  calculCircle = (numero, nbrArticles, user) => {
    let self = this;

    let min = 250; // no read
    let max = 155; // all read

    let nbrReaded = self.getArticlesReadByNumero(user, numero).length;
    if (!nbrReaded) return min;

    let diff = nbrReaded / nbrArticles;

    let differentiel = min - max;
    return min - diff * differentiel;
  };

  getTopValueView() {
    if (typeof window === "undefined") return "0px";
    if (!document.querySelector("header.header")) return "0px";
    let header = document.querySelector("header.header").getBoundingClientRect()
      .height;
    return header + "px";
  }

  render() {
    let that = this;

    const classes = classNames({
      active: this.props.menu,
      menuNumero: true,
      visible: this.state.imageRatio.width,
    });

    let style = {
      marginTop: this.getTopValueView(),
    };

    return (
      <SettingsContext.Consumer>
        {({ fonts, kiosque }) => (
          <Query
            query={GET_MENU_NUMEROS}
            variables={{
              magazine_slug: this.context.magazine.slug,
            }}
            fetchPolicy="cache-only"
          >
            {({ loading, error, data, updateQuery, client, networkStatus }) => {
              let numeros = data.datas.menuNumeros;

              let style2 = {
                backgroundImage: "url(" + kiosque.image + ")",
              };

              return (
                <div className={classes} style={style}>
                  <header className="header-kiosque">
                    <div className="left-bloc">
                      <div className="inner" style={style2} />
                    </div>
                    <div className="right-bloc">
                      <h1 style={fonts.family1}>{kiosque.titre}</h1>
                      {kiosque.sous_titre && (
                        <span style={fonts.family1}>{kiosque.sous_titre}</span>
                      )}
                      {kiosque.logo && <img src={kiosque.logo} alt="logo" />}
                    </div>
                  </header>
                  <MenuNumeroFilter numeros={numeros}>
                    {(filter) => {
                      let filtered = numeros.filter((numero) =>
                        filter ? numero.type.slug === filter.slug : true
                      );

                      if (filtered) {
                        return (
                          <div className="menuNumeros">
                            {filtered.map((numero) => {
                              return (
                                <NumeroInList
                                  numero={numero}
                                  calculCircle={this.calculCircle}
                                  menu={this.props.menu}
                                  user={this.props.user}
                                  changeNumeroAndGoLink={
                                    this.changeNumeroAndGoLink
                                  }
                                  getUrlNumeroItem={this.getUrlNumeroItem}
                                  isNumeroRead={this.isNumeroRead}
                                  key={numero._id}
                                  imageRatio={this.state.imageRatio}
                                  changeImageRatio={this.changeImageRatio}
                                  handleChange={this.handleChange}
                                />
                              );
                            })}
                          </div>
                        );
                      } else {
                        return null;
                      }
                    }}
                  </MenuNumeroFilter>
                  <FooterMenu
                    fonts={fonts}
                    toggleMenu={this.props.toggleMenu}
                    openRGPDPopup={this.props.openRGPDPopup}
                    history={this.props.history}
                    setMenuNumero={this.props.setMenuNumero}
                  />
                </div>
              );
            }}
          </Query>
        )}
      </SettingsContext.Consumer>
    );
  }
}

MenuNumeroView.contextTypes = {
  magazine: PropTypes.object,
  resetCache: PropTypes.bool,
};
