import { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as React from "react";

import { SettingsContext } from "../../../../../Magazine/MagazineSettings";
import classNames from "classnames";

class SearchBtn extends Component {
  shouldComponentUpdate(prevProps) {
    // return (this.props.video && prevProps.video !== this.props.video)
    return (
      prevProps.video !== this.props.video ||
      prevProps.search !== this.props.search
    );
  }

  render() {
    return (
      <SettingsContext.Consumer>
        {({ fonts, logo, custom }) => {
          if (this.props.video) {
            return (
              <div
                className="video-action return"
                onClick={this.props.videoOut}
                data-text="true"
              >
                <div className="inner">
                  <i className="icon-arrow_left" />
                  <span>Quitter</span>
                </div>
              </div>
            );
          }

          if (!this.props.video) {
            return (
              <SearchBtnView
                history={this.props.history}
                slug={this.props.slug}
                menuNumero={this.props.menuNumero}
                menuShow={this.props.menuShow}
                setMenuNumero={this.props.setMenuNumero}
                setMenuInner={this.props.setMenuInner}
                toggleMenu={this.props.toggleMenu}
                isPage={this.props.isPage}
                toggleSearch={this.props.toggleSearch}
                search={this.props.search}
              />
            );
          }

          return null;
        }}
      </SettingsContext.Consumer>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    video: state.video,
    search: state.search,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    videoOut: () => {
      dispatch({
        type: "VIDEO_OUT",
      });
    },
    toggleSearch: () => {
      dispatch({
        type: "TOGGLE_SEARCH",
      });
    },
    toggleMenuOff: () => {
      dispatch({
        type: "TOGGLE_SEARCH",
      });
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SearchBtn)
);

export class SearchBtnView extends Component {
  constructor(props) {
    super(props);
  }

  toggle = (toggle) => {
    this.props.setMenuNumero(false);
    this.props.setMenuInner(false);

    if (!toggle) {
      this.props.toggleMenu(toggle);
    }

    this.props.toggleSearch();
  };

  toggleHome = () => {
    this.props.toggleMenu(false);
    this.props.setMenuInner(false);
    //this.props.history.push("/" + this.props.slug);
    this.props.toggleMenu(true);
  };

  render() {
    const classes = classNames({
      searchBtn: true,
      isSearching: this.props.search.isSearching || this.props.isPage,
    });

    const classes2 = classNames({
      "menu-search": true,
      isSearching: this.props.search.isSearching || this.props.isPage,
    });

    return (
      <div className={classes2}>
        {!this.props.isPage && (
          <button
            className={classes}
            onClick={() => this.toggle(this.props.search.isSearching)}
            data-text="true"
          >
            {!this.props.search.isSearching && <i className="ico-ph-search" />}
            {this.props.search.isSearching && !this.props.isLateralBar && (
              <i className="ico-original-close" />
            )}
            {this.props.search.isSearching && this.props.isLateralBar && (
              <i className="ico-ph-search" />
            )}
          </button>
        )}
        {this.props.isPage && !this.props.menuShow && !this.props.menuNumero && (
          <button
            className={classes}
            onClick={() => this.toggleHome()}
            data-text="true"
          >
            <i className="ico-original-close" />
          </button>
        )}
      </div>
    );
  }
}
