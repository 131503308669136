import { Link } from "react-router-dom";
import * as React from "react";

export const ButtonGo = ({
  isPrev,
  isNext,
  numero,
  article,
  next,
  prev,
  isFirstArticle,
  isLastArticle,
  prevChapitre,
  nextChapitre,
  fonts,
  clickE,
}) => {
  let phrase = "";
  let url = "";
  let icon = "";

  const obj = {
    next: "Suivant",
    prev: "Précédent",
    nextChapitre: "Suivant",
    prevChapitre: "Précédent",
    end: "Suivant",
    start: "Précédent",
  };

  if (isFirstArticle) {
    if (isPrev) {
      if (prevChapitre.chapitre) {
        if (prevChapitre.article) {
          url = `/${numero.slug}/${prevChapitre.chapitre.slug}/${prevChapitre.article.slug}`;
          phrase = obj.prevChapitre;
        } else {
          url = `/${numero.slug}/${prevChapitre.chapitre.slug}`;
          phrase = obj.prevChapitre;
        }
      } else {
        url = `/${numero.slug}`;
        phrase = obj.end;
      }

      icon = "ico-ph-prev-article";
    }
  }

  if (isLastArticle) {
    if (isNext) {
      if (nextChapitre.chapitre) {
        if (nextChapitre.article) {
          url = `/${numero.slug}/${nextChapitre.chapitre.slug}/${nextChapitre.article.slug}`;
          phrase = obj.nextChapitre;
        } else {
          url = `/${numero.slug}/${nextChapitre.chapitre.slug}/`;
          phrase = obj.nextChapitre;
        }
      } else {
        url = `/${numero.slug}`;
        phrase = obj.end;
      }

      icon = "ico-ph-next-article";
    }
  }

  if (prev) {
    if (isPrev) {
      url = `/${numero.slug}/${article.category.slug}/${prev.slug}`;
      phrase = obj.prev;
      icon = "ico-ph-prev-article";
    }
  }

  if (next) {
    if (isNext) {
      url = `/${numero.slug}/${article.category.slug}/${next.slug}`;
      phrase = obj.next;
      icon = "ico-ph-next-article";
    }
  }

  return (
    <Link to={url} style={fonts.family1} onClick={clickE}>
      <i className={icon} />
      <span>{phrase}</span>
    </Link>
  );
};
